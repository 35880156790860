import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import Router from "./Router";
import Footer from "./components/Footer";

function App() {
    return (
        <>
            <div className="App">

                <Router/>


            </div>
            <Footer/>
        </>
    );
}

export default App;
