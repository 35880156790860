import React from "react";


const Footer = () => {


    return (
        <footer>
            <div className="text-center">Copyright © 2021 Psychomed.com® All rights reserved.</div>
        </footer>
    )
}

export default Footer;